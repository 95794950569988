<template>
  <div class="text-primary-500 text-sm">
    <p>ご不便をおかけし申し訳ございません。</p>
    <template v-if="decodedError === 'maintenance'">
      <p>ただいまメンテナンス中です。</p>
      <p class="mt-4">しばらくたってからアクセスお願いいたします。</p>
      <div class="mt-4 w-16">
        <img src="@/assets/emoji/1f647-2640.png" alt="TFMS Portal" />
      </div>
      <div class="mt-8">
        <router-link to="/" class="text-indigo-400 underline">
          再読み込み
        </router-link>
      </div>
    </template>
    <template v-else>
      <p>予期しないエラーが発生しました。</p>
      <p class="mt-4">調査のため、恐れ入りますが下記情報を</p>
      <p>システム管理者に共有してください。</p>
      <div class="bg-level-1 p-4 mt-4">
        <code class="font-mono block mb-3 break-words text-xs max-h-48 overflow-y-scroll">
          {{ decodedError }}
        </code>
        <button
          v-clipboard:copy="decodedError"
          v-clipboard:success="onCopy"
          type="button"
          class="bg-level-3 px-6 py-2 rounded-lg shadow-lg focus:outline-none"
        >
          コピーする
        </button>
      </div>
      <div class="mt-8">
        <router-link to="/" class="text-indigo-400 underline">
          ホームに戻る
        </router-link>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'ServerError',
  props: {
    error: {
      type: String,
      default: '',
    },
  },
  computed: {
    decodedError() {
      return decodeURIComponent(this.error)
    },
  },
  methods: {
    onCopy() {
      this.$store.dispatch('notification/set', {
        type: 'success',
        message: 'コピーしました',
      })
    },
  },
}
</script>
